import React from 'react';
import * as Sentry from "@sentry/react";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import FormRechazoSolicitud from '../components/FormRechazoSolicitud';
import FormRechazoCotizacion from '../components/FormRechazoCotizacion';
import { getQueryParams, formatoRut } from '../services/utils';
import { crear, leer } from '../services/api';
import { getCurrentUser } from '../services/auth';
import * as solicitudesUsuarioDuck from '../ducks/SolicitudesUsuario';
import * as solicitudesAprobarDuck from '../ducks/SolicitudesAprobar';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as cotizacionesDuck from '../ducks/Cotizaciones';
import * as mensajesDuck from '../ducks/Mensajes';
import * as itemsDuck from '../ducks/Items';
import * as itemsCotizadosDuck from '../ducks/ItemsCotizados';
import * as aprobador1Duck from '../ducks/Aprobador1';
import * as aprobador2Duck from '../ducks/Aprobador2';
import {
  USUARIO_COMPRAS_MENORES,
  APROBADOR_GERENTE_GAF,
} from "../utils/constants";

class AccionDirecta extends React.Component {
    constructor(props) {
        super(props);
        let params = getQueryParams();
        this.state = {
            loading: true,
            openAprobar: false,
            openModalRechazo: false,
            openModalRechazoSolicitud: false,
            confirmacion: true,
            showActions: true,
            showFormRechazo: true,
            botonSi: false,
            botonNo: false,
            queryParams: params,
            esSolicitud: params.idCot ? false : true,
            solicitudActual: null,
            cotizacionActual: null
        };
    }

    componentDidMount = async () => {
        const { queryParams } = this.state;
        const {
            assignMensaje,
            usuarioAutenticado,
            detallesSolicitud,
            consultarCotizacion,
            fetchAprobador1,
            fetchAprobador2,
        } = this.props;
        console.log("inicio fetch accion");
        
        let cotizacionActual = queryParams.idCot ? await consultarCotizacion(queryParams.idCot) : null;
        let solicitudActual =  queryParams.idSol ? await detallesSolicitud(queryParams.idSol) : null;
        if (solicitudActual) await Promise.all([
            fetchAprobador1(solicitudActual.id, usuarioAutenticado.data.id),
            fetchAprobador2(solicitudActual.id, usuarioAutenticado.data.id),
        ]);
        console.log("fin fetch accion");
        const { aprobador1, aprobador2 } = this.props;

        if (queryParams.idSol && queryParams.idCot && queryParams.tipo && solicitudActual && cotizacionActual) {
            let codSol = solicitudActual.codigo;
            let codCot = cotizacionActual.codigo;

            if (cotizacionActual.activo) {
                let aprobadorCotRango1 = aprobador2;
                let aprobadorCotRango2 = solicitudActual.gerenteId === usuarioAutenticado.data.id;
                let centroFirmaId = (await leer(`/centro-logistico/${cotizacionActual.ubicacion_logistica_id}`)).usuario_firma_oc_id;
                // let esPagoInmediato = ['Anticipo', 'Pago Online', 'Contado'].indexOf(cotizacionActual.condicion_pago) > -1;

                // if ((!cotizacionActual.revisa_centro_id && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297"                
                // Se modifica el 07/11/2023 habilitando a Aline realizar aprobaciones sobre su monto de $300.000 hasta los $5.000.000 por reemplazo de Juanjo   
                if ((!cotizacionActual.revisa_centro_id && usuarioAutenticado.data.id == USUARIO_COMPRAS_MENORES)
                        // && !cotizacionActual.es_gasto_reemb && !cotizacionActual.es_tarifa_gestion 
                        // && cotizacionActual.monto_total_pesos < 300000 ) //Falta aprobación Compras Aline   Monto Anterior de $300.000
                    || (!cotizacionActual.revisa_centro_id && usuarioAutenticado.data.id == centroFirmaId 
                        && (cotizacionActual.es_gasto_reemb || cotizacionActual.es_tarifa_gestion || cotizacionActual.monto_total_pesos >= 300000)) //Falta aprobación Compras JRamos
                    || (cotizacionActual.revisa_centro_id && cotizacionActual.es_gasto_reemb 
                        && (cotizacionActual.monto_total_pesos < 5000000 || cotizacionActual.aprobador_id)
                        // && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Gasto Reemb, falta aprobación GAF
                        && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Gasto Reemb, falta aprobación GAF
                    || (cotizacionActual.revisa_centro_id && cotizacionActual.es_gasto_reemb && !cotizacionActual.aprobador_id && aprobadorCotRango2) //Gasto Reemb, falta aprobación Gerente
                    || (cotizacionActual.revisa_centro_id && cotizacionActual.es_tarifa_gestion 
                        // && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Tarifa Gestión, falta aprobación GAF
                        && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Tarifa Gestión, falta aprobación GAF
                    || (cotizacionActual.revisa_centro_id && !cotizacionActual.es_gasto_reemb && !cotizacionActual.es_tarifa_gestion
                        && !cotizacionActual.aprobador_id && cotizacionActual.monto_total_pesos < 5000000 && aprobadorCotRango1) //Otros, falta aprobación Director Proyectos
                    || (cotizacionActual.revisa_centro_id && !cotizacionActual.es_gasto_reemb && !cotizacionActual.es_tarifa_gestion
                        && !cotizacionActual.aprobador_id && cotizacionActual.monto_total_pesos >= 5000000 && aprobadorCotRango2) //Otros, falta aprobación Gerente
                    || (cotizacionActual.revisa_centro_id && !cotizacionActual.es_gasto_reemb && !cotizacionActual.es_tarifa_gestion
                        // && cotizacionActual.aprobador_id && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Otros, falta aprobación GAF
                        && cotizacionActual.aprobador_id && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Otros, falta aprobación GAF
                    ) {
                    if (cotizacionActual.estado.id == 23) {
                        assignMensaje('La cotización ya se encuentra aprobada.');
                        this.setState({
                            ...this.state,
                            loading: false,
                            confirmacion: false,
                            openAprobar: true,
                            botonNo: false
                        });
                    } else if (cotizacionActual.estado.id == 24) {
                        assignMensaje('La cotización ya se encuentra rechazada.');
                        this.setState({
                            ...this.state,
                            loading: false,
                            confirmacion: false,
                            openAprobar: true,
                            botonNo: false
                        });
                    } else if (cotizacionActual.estado.id == 21) {
                        assignMensaje('Debe esperar que el responsable solicite la aprobación.');
                        this.setState({
                            ...this.state,
                            loading: false,
                            confirmacion: false,
                            openAprobar: true,
                            botonNo: false
                        });
                    } else {
                        switch (queryParams.tipo) {
                            case 'aprobarCotizacion':
                                // if (cotizacionActual.aprobador_id && usuarioAutenticado.data.id == centroFirmaId) {
                                //     assignMensaje('¿Está seguro de crear y firmar OC para la cotización ' + codCot + '?');
                                // } else {
                                //     assignMensaje('¿Está seguro de aprobar la cotización ' + codCot + '?');
                                // }
                                this.setState({
                                    ...this.state,
                                    // loading: false,
                                    confirmacion: false,
                                    openAprobar: true,
                                    // botonSi: 'Sí',
                                    // botonNo: 'No',
                                    solicitudActual: solicitudActual,
                                    cotizacionActual: cotizacionActual
                                });
                                this.handleAprobarCotizacion(cotizacionActual, solicitudActual);
                                break;
                            case 'rechazarCotizacion':
                                this.setState({
                                    ...this.state,
                                    confirmacion: false,
                                    openModalRechazo: true,
                                    solicitudActual: solicitudActual,
                                    cotizacionActual: cotizacionActual
                                });
                                assignMensaje('Rechazar cotización ' + codCot + ' con el siguiente comentario:');
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                break;
                            default:
                                assignMensaje('Tipo de acción no reconocida.');
                                this.setState({
                                    ...this.state,
                                    loading: false,
                                    confirmacion: false,
                                    openAprobar: true,
                                    botonNo: false
                                });
                        }
                    }
                } else {
                    assignMensaje('Usted no cuenta con los permisos para aprobar o rechazar la cotización ' + codCot);
                    this.setState({
                        ...this.state,
                        loading: false,
                        confirmacion: false,
                        openAprobar: true,
                        botonNo: false
                    });
                }
            } else {
                assignMensaje('La cotización ' + codCot + ' no puede ser procesada ya que ha sido borrada.');
                    this.setState({
                        ...this.state,
                        loading: false,
                        confirmacion: false,
                        openAprobar: true,
                        botonNo: false
                    });
            }
        } else if (queryParams.idSol && queryParams.tipo && solicitudActual) {
            let codSol = solicitudActual.codigo;
            // console.log("sol a aprobar", solicitudActual);
            if (solicitudActual.estado == 4) {
                assignMensaje('La solicitud se encuentra rechazada.');
                this.setState({
                    ...this.state,
                    loading: false,
                    confirmacion: false,
                    openAprobar: true,
                    botonNo: false
                });
            } else if (solicitudActual.estado > 2) {
                assignMensaje('La solicitud ya se encuentra aprobada.');
                this.setState({
                    ...this.state,
                    loading: false,
                    confirmacion: false,
                    openAprobar: true,
                    botonNo: false
                });
            } else {
                if (((aprobador1 && solicitudActual.estado === 1) || (aprobador2 && solicitudActual.estado <= 2))) {
                    switch (queryParams.tipo) {
                        case 'aprobarSolicitud':
                            // assignMensaje('¿Está seguro de aprobar la solicitud ' + codSol + '?');
                            this.setState({
                                ...this.state,
                                // loading: false,
                                confirmacion: false,
                                openAprobar: true,
                                // botonSi: 'Sí',
                                // botonNo: 'No',
                                solicitudActual: solicitudActual
                            });
                            this.handleAprobarSolicitud();
                            break;
                        case 'rechazarSolicitud':
                            this.setState({
                                ...this.state,
                                confirmacion: false,
                                openModalRechazoSolicitud: true,
                                solicitudActual: solicitudActual
                            });
                            assignMensaje('Rechazar solicitud ' + codSol + ' con el siguiente comentario:');
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            break;
                        default:
                            assignMensaje('Tipo de acción no reconocida.');
                            this.setState({
                                ...this.state,
                                loading: false,
                                confirmacion: false,
                                openAprobar: true,
                                botonNo: false
                            });
                    }
                } else {
                    
                    assignMensaje('Usted no cuenta con los permisos para aprobar o rechazar la solicitud ' + codSol);
                    this.setState({
                        ...this.state,
                        loading: false,
                        confirmacion: false,
                        openAprobar: true,
                        botonNo: false
                    });
                }
            }
        } else if (queryParams.tipo == "guardarEvalCosto" && queryParams.cotId && queryParams.provId) {
            if (queryParams.costo) {
                let cotizacionEval = await consultarCotizacion(queryParams.cotId);
                if (cotizacionEval?.evaluaciones_costo?.length) {
                    assignMensaje('La cotización ya tiene una Evaluación de Costos guardada.');
                    this.setState({
                        ...this.state,
                        loading: false,
                        confirmacion: false,
                        openAprobar: true,
                        botonNo: false
                    });
                } else {
                    let evalCosto = {
                        cotizacion_id: parseInt(queryParams.cotId),
                        proveedor_id: parseInt(queryParams.provId),
                        costo: queryParams.costo,
                        usuario_id: usuarioAutenticado.data.id,
                        activo: true
                    };

                    try {
                        await crear('/evaluacion-costo-proveedor', evalCosto);
                        assignMensaje('Evaluación guardada correctamente');
                        this.setState({
                            ...this.state,
                            loading: false,
                            confirmacion: false,
                            openAprobar: true,
                            showActions: true,
                            botonNo: 'Cerrar'
                        });
                    } catch (e) {
                        assignMensaje('Ha ocurrido un error, recargue la página y vuelva a intentarlo');
                        this.setState({
                            ...this.state,
                            loading: false,
                            confirmacion: false,
                            openAprobar: true,
                            showActions: true,
                            botonNo: 'Cerrar'
                        });
                    };
                }
            } else {
                assignMensaje('No seleccionó ninguna opción de evaluación. Seleccione una y vuelva a intentarlo.');
                this.setState({ ...this.state, loading: false, confirmacion: false, openAprobar: true, botonNo: false });
            }
        } else {
            assignMensaje('Faltan datos para realizar esta acción. Vuelva a intentarlo.');
            this.setState({ ...this.state, loading: false, confirmacion: false, openAprobar: true, botonNo: false });
        }
    };

    handleClose = () => {
        const { history } = this.props;

        this.setState({
            ...this.state,
            openAprobar: false
        });

        history.replace('/');
    };

    handleAprobarSolicitud = async () => {
        const { queryParams } = this.state;
        const { aprobarSolicitud, assignMensaje, usuarioAutenticado } = this.props;
        this.setState({ ...this.state, loading: true, showActions: false });
        const solicitudAprobada = await aprobarSolicitud(queryParams.idSol, usuarioAutenticado.data.id);
        if (solicitudAprobada) {
            assignMensaje('Solicitud Aprobada');
            this.setState({
                ...this.state,
                loading: false,
                confirmacion: false,
                showActions: true,
                botonNo: 'Cerrar'
            });
        } else {
            assignMensaje(`Ha ocurrido un error, vuelva a intentarlo`);
            this.setState({
                ...this.state,
                loading: false,
                confirmacion: true,
                showActions: true,
                botonSi: 'Reintentar',
                botonNo: 'Cerrar'
            });
        }
    };

    handleAprobarCotizacion = async (cotizacionActual, solicitudActual) => {
        const { queryParams } = this.state;
        const {
            rechazarCotizacion,
            resetItemsCotizados,
            assignMensaje,
            aprobarCotizacion,
            editarCotizaciones,
            solicitarAprobacionCotizacion,
            usuarioAutenticado,
            editarItems,
            aprobador2,
        } = this.props;
        // assignMensaje(`Procesando...`);
        this.setState({ ...this.state, loading: true, showActions: false });
        const itemsCotizacion = cotizacionActual.item_cotizacion;
        const itemsCotizados = solicitudActual.items.filter(x => itemsCotizacion.some(y => y.item_recurso_id === x.id));
        if (!itemsCotizados.some(x => x.estado !== 22)) {
            try {
                let totalCotizacion =
                    cotizacionActual.tipo_moneda == 'CLP'
                        ? cotizacionActual.monto_total
                        : cotizacionActual.monto_total_pesos;
                let crearOC = false;
                let centroFirmaId = (await leer(`/centro-logistico/${cotizacionActual.ubicacion_logistica_id}`)).usuario_firma_oc_id;;
                let esAprobador1 = aprobador2;
                let esAprobador2 = solicitudActual.gerenteId === usuarioAutenticado.data.id;

                if (cotizacionActual.es_gasto_reemb) {
                    //Es compra Gasto Reembolsable
                    if (cotizacionActual.revisa_centro_id) {
                        //Ya está aprobada por Compras
                        if (totalCotizacion < 5000000) {
                            //Está aprobando GAF y genera OC
                            crearOC = true;
                            await editarCotizaciones(queryParams.idCot, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                revisa_gaf_id: usuarioAutenticado.data.id,
                                fecha_revisa_gaf: moment().toISOString()
                            });
                        } else if (cotizacionActual.aprobador_id) {
                            //Está aprobando GAF y genera OC
                            crearOC = true;
                            await editarCotizaciones(queryParams.idCot, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                revisa_gaf_id: usuarioAutenticado.data.id,
                                fecha_revisa_gaf: moment().toISOString()
                            });
                        } else {
                            //Está aprobando Gerente
                            let dataEditar = {
                                aprobador_id: usuarioAutenticado.data.id,
                                fecha_aprobacion: moment().toISOString()
                            };
                            // if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF){
                                //Si el usuario es GAF, se guarda su aprobación y genera OC.
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                            } else {
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                                await solicitarAprobacionCotizacion(queryParams.idCot);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    loading: false,
                                    confirmacion: false,
                                    showActions: true,
                                    botonNo: 'Cerrar'
                                });
                            }
                        }
                    } else {
                        //Está aprobando Compras
                        let dataEditar = {
                            revisa_centro_id: usuarioAutenticado.data.id,
                            fecha_revisa_centro: moment().toISOString()
                        };
                        // if (totalCotizacion < 5000000 && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                        if (totalCotizacion < 5000000 && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                            //Si es menor a 5MM y usuario es GAF, se guarda su aprobación y genera OC.
                            crearOC = true;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_revisa_gaf = moment().toISOString();
                            await editarCotizaciones(queryParams.idCot, dataEditar);
                        } else if (totalCotizacion >= 5000000 && esAprobador2) {
                            //Si es mayor a 5MM y usuario es Gerente, se guarda su aprobación.
                            dataEditar.aprobador_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_aprobacion = moment().toISOString();
                            // if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                                //Si el usuario es GAF, se guarda su aprobación y genera OC.
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                            } else {
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                                await solicitarAprobacionCotizacion(queryParams.idCot);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    loading: false,
                                    confirmacion: false,
                                    showActions: true,
                                    botonNo: 'Cerrar'
                                });
                            }
                        } else {
                            await editarCotizaciones(queryParams.idCot, dataEditar);
                            await solicitarAprobacionCotizacion(queryParams.idCot);
                            assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                            resetItemsCotizados();
                            this.setState({
                                loading: false,
                                confirmacion: false,
                                showActions: true,
                                botonNo: 'Cerrar'
                            });
                        }
                    }
                } else if (cotizacionActual.es_tarifa_gestion) {
                    //Es compra Tarifa de Gestión
                    if (cotizacionActual.revisa_centro_id) {
                        //Ya está aprobada por Compras, está aprobando GAF y genera OC
                        crearOC = true;
                        await editarCotizaciones(queryParams.idCot, {
                            estado_id: 23,
                            fecha_orden: moment().toISOString(),
                            revisa_gaf_id: usuarioAutenticado.data.id,
                            fecha_revisa_gaf: moment().toISOString()
                        });
                    } else {
                        //Está aprobando Compras
                        let dataEditar = {
                            revisa_centro_id: usuarioAutenticado.data.id,
                            fecha_revisa_centro: moment().toISOString()
                        };
                        if (totalCotizacion < 10000000) {
                            //Si es menor a 10MM genera OC.
                            crearOC = true;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            await editarCotizaciones(queryParams.idCot, dataEditar);
                        // } else if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                        } else if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                            //Si el usuario es GAF, se guarda su aprobación y genera OC.
                            crearOC = true;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_revisa_gaf = moment().toISOString();
                            await editarCotizaciones(queryParams.idCot, dataEditar);
                        } else {
                            await editarCotizaciones(queryParams.idCot, dataEditar);
                            await solicitarAprobacionCotizacion(queryParams.idCot);
                            assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                            resetItemsCotizados();
                            this.setState({
                                loading: false,
                                confirmacion: false,
                                showActions: true,
                                botonNo: 'Cerrar'
                            });
                        }
                    }
                } else {
                    //Es compra Otros
                    if (cotizacionActual.revisa_centro_id) {
                        //Ya está aprobada por Compras
                        if (totalCotizacion < 5000000) {
                            //Está aprobando Director y genera OC
                            crearOC = true;
                            await editarCotizaciones(queryParams.idCot, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                aprobador_id: usuarioAutenticado.data.id,
                                fecha_aprobacion: moment().toISOString()
                            });
                        } else if (cotizacionActual.aprobador_id) {
                            //Está aprobando GAF y genera OC
                            crearOC = true;
                            await editarCotizaciones(queryParams.idCot, {
                                estado_id: 23,
                                fecha_orden: moment().toISOString(),
                                revisa_gaf_id: usuarioAutenticado.data.id,
                                fecha_revisa_gaf: moment().toISOString()
                            });
                        } else {
                            //Está aprobando Gerente
                            let dataEditar = {
                                aprobador_id: usuarioAutenticado.data.id,
                                fecha_aprobacion: moment().toISOString()
                            };
                            if (totalCotizacion < 10000000) {
                                //Si es menor a 10MM genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                            // } else if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            } else if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                                //Si usuario es GAF, se guarda su aprobación y genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                            } else {
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                                await solicitarAprobacionCotizacion(queryParams.idCot);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    loading: false,
                                    confirmacion: false,
                                    showActions: true,
                                    botonNo: 'Cerrar'
                                });
                            }
                        }
                    } else {
                        //Está aprobando Compras
                        let dataEditar = {
                            revisa_centro_id: usuarioAutenticado.data.id,
                            fecha_revisa_centro: moment().toISOString()
                        };
                        if (totalCotizacion < 1000000) {
                            //Si es menor a 1MM genera OC
                            crearOC = true;
                            centroFirmaId = usuarioAutenticado.data.id;
                            dataEditar.estado_id = 23;
                            dataEditar.fecha_orden = moment().toISOString();
                            await editarCotizaciones(queryParams.idCot, dataEditar);
                        } else if (totalCotizacion < 5000000) {
                            if (esAprobador1) {
                                //Si es entre 1MM-5MM y usuario es Director
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.aprobador_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_aprobacion = moment().toISOString();
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                            } else {
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                                await solicitarAprobacionCotizacion(queryParams.idCot);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    loading: false,
                                    confirmacion: false,
                                    showActions: true,
                                    botonNo: 'Cerrar'
                                });
                            }
                        } else if (esAprobador2) {
                            //Si usuario es Director se guarda su aprobación
                            dataEditar.aprobador_id = usuarioAutenticado.data.id;
                            dataEditar.fecha_aprobacion = moment().toISOString();
                            if (totalCotizacion < 10000000) {
                                //Si es menor a 10MM genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                            // } else if (usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") {
                            } else if (usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) {
                                //Si usuario es GAF, se guarda su aprobación y genera OC
                                crearOC = true;
                                dataEditar.estado_id = 23;
                                dataEditar.fecha_orden = moment().toISOString();
                                dataEditar.revisa_gaf_id = usuarioAutenticado.data.id;
                                dataEditar.fecha_revisa_gaf = moment().toISOString();
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                            } else {
                                await editarCotizaciones(queryParams.idCot, dataEditar);
                                await solicitarAprobacionCotizacion(queryParams.idCot);
                                assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                                resetItemsCotizados();
                                this.setState({
                                    loading: false,
                                    confirmacion: false,
                                    showActions: true,
                                    botonNo: 'Cerrar'
                                });
                            }
                        } else {
                            await editarCotizaciones(queryParams.idCot, dataEditar);
                            await solicitarAprobacionCotizacion(queryParams.idCot);
                            assignMensaje('Cotización aprobada y enviada al siguiente aprobador.');
                            resetItemsCotizados();
                            this.setState({
                                loading: false,
                                confirmacion: false,
                                showActions: true,
                                botonNo: 'Cerrar'
                            });
                        }
                    }
                }

                if (crearOC) {
                    let dataOC = await aprobarCotizacion(
                        queryParams.idCot,
                        { ...cotizacionActual, estado_id: 23 },
                        centroFirmaId
                    );

                    await Promise.all(
                        itemsCotizados.map(async y => {
                            return {
                                items: await editarItems(y.id, {
                                    ...y,
                                    fecha_requerida: moment(y.fecha_requerida).toISOString(),
                                    estado: 12
                                })
                            };
                        })
                    );

                    let cotizacionesRestantes = solicitudActual.cotizaciones.filter(x => x.id !== parseInt(queryParams.idCot) && x.estado_id === 22);
                    // Si algunas de las otras cotizaciones pendientes tienen algún ítem que se aprobó en esta cotización, se rechaza esa cotización.
                    for (let i = 0; i < cotizacionesRestantes.length; i++) {
                        if (cotizacionesRestantes[i].item_cotizacion.some(x => itemsCotizados.some(y => y.id === x.item_recurso_id))) {
                            await rechazarCotizacion(cotizacionesRestantes[i].id, {
                                ...cotizacionesRestantes[i],
                                estado_id: 24,
                                comentario: `Rechazada automáticamente por aprobación de ${cotizacionActual.codigo}`,
                                cotizacion_id: cotizacionesRestantes[i].id,
                                fecha: moment().toISOString(),
                                solicitud_recurso_id: solicitudActual.id,
                                usuario_id: usuarioAutenticado.data.id,
                                aprobada: 0
                            });
                        }
                    }

                    if (['Anticipo', 'Pago Online'].indexOf(cotizacionActual.condicion_pago) > -1) {
                        // Enviar a Gestor de Pagos como pago directo
                        let montoNeto = totalCotizacion ? parseInt(totalCotizacion) : 0;
                        let montoIva = cotizacionActual.iva
                          ? cotizacionActual.tipo_moneda == 'CLP' || cotizacionActual.tipo_moneda == 'PEN'
                            ? parseInt(cotizacionActual.iva)
                            : parseInt(parseFloat(cotizacionActual.iva) * parseFloat(cotizacionActual.cambio_moneda))
                          : 0;
                        if (cotizacionActual.iva) montoNeto = montoNeto - montoIva;
                        let provCot= await leer(`/proveedor/${cotizacionActual.proveedor_id}`);
                        let empresa = await leer(`/empresa-cyd/${cotizacionActual.empresa_cyd_id}`);

                        let datosPago = {
                            estado: "Pendiente de Pago",
                            tipo_rendicion: "Pago inmediato",
                            numero_factura: "",
                            monto_total: totalCotizacion ? parseInt(totalCotizacion) : 0,
                            monto_iva: montoIva,
                            monto_neto: montoNeto,
                            monto_total_dolares: 0,
                            clasificacion_factura: "",
                            es_exenta: montoIva == 0,
                            descripcion: 'Pago inmediato Orden de compra ' + dataOC.codigo +' gestionada a través del SDA.',
                            comentario: "",
                            dias_vencimiento: 0,
                            fecha_factura: null,
                            fecha_vencimiento: moment().format(),
                            _contrato_revision_ref: null,
                            _gerencia_revision_ref: null,
                            _sociedad_ref: null,
                            contrato: solicitudActual.contrato._id,
                            // contrato: "5f7e0fb7952c2d0019e8721f",
                            gerencia: null,
                            sociedad: formatoRut(empresa.rut.replace('-', '')),
                            proveedor: {
                                nombre: provCot.nombre,
                                rut: provCot.rut,
                                correo_transferencia: provCot.banco_email,
                                numero_cuenta: provCot.banco_numero_cuenta,
                                tipo_cuenta: provCot.banco_tipo_cuenta,
                                banco: provCot.banco_nombre
                            },
                            codigo_orden_compra: dataOC.codigo,
                            codigo_solicitud_recurso: solicitudActual.codigo,
                            id_orden_compra: dataOC.id,
                            id_usuario_aprobador_sda: usuarioAutenticado.data.id,
                            aprobador_sda: usuarioAutenticado.data.nombres,
                            archivo_factura: null,
                            id_archivo_oc: dataOC.archivo_id,
                            id_archivo_respaldo: cotizacionActual.archivo_id,
                            condicion_pago: cotizacionActual.condicion_pago,
                            creador_id: cotizacionActual.creador_id
                        }

                        console.log("datos pago: ", datosPago);
                        let token = await getCurrentUser();
                        // let response = await fetch('http://localhost:8000/facturas/pago-directo', {
                        let response = await fetch('https://api-rendiciones.cydocs.cl/facturas/pago-directo', {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token.token
                            },
                            body: JSON.stringify(datosPago)
                        });
                        const jsonResponse = await response.json();
                        if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                            let error;
                            if (jsonResponse && jsonResponse.errors) {
                                error = jsonResponse.errors;
                            }
                            console.log("errores: ", error);
                            Sentry.captureMessage(`Error envío Pago Inmediato, oc: ${dataOC.codigo}`);
                        }
                        console.log("resultado pago directo: ", jsonResponse);
                    }

                    assignMensaje(`Cotización aprobada`);
                    // resetItemsCotizados();
                    // selectCotizacion('');
                    this.setState({
                        ...this.state,
                        loading: false,
                        confirmacion: false,
                        showActions: true,
                        botonNo: 'Cerrar'
                    });
                }
            } catch (e) {
                console.log(e);
                assignMensaje(`Ha ocurrido un error, vuelva a intentarlo`);
                resetItemsCotizados();
                this.setState({
                    ...this.state,
                    loading: false,
                    confirmacion: true,
                    showActions: true,
                    botonSi: 'Reintentar',
                    botonNo: 'Cerrar'
                });
            }
        } else if (itemsCotizados.some(x => x.estado === 8)) {
            assignMensaje(`Debe esperar que el responsable solicite la aprobación`);
            resetItemsCotizados();
            this.setState({
                ...this.state,
                loading: false,
                confirmacion: false,
                showActions: true,
                botonNo: 'Cerrar'
            });
        } else {
            assignMensaje(`Items ya fueron aprobados en otra cotización`);
            resetItemsCotizados();
            this.setState({
                ...this.state,
                loading: false,
                confirmacion: false,
                showActions: true,
                botonNo: 'Cerrar'
            });
        }
    };

    handleRechazarSolicitud = async values => {
        const { queryParams } = this.state;
        const { rechazarSolicitud, usuarioAutenticado, assignMensaje } = this.props;
        const form = {
            ...values,
            fecha: moment().toISOString(),
            solicitud_recurso_id: parseInt(queryParams.idSol),
            usuario_id: usuarioAutenticado.data.id,
            estado_id: 4
        };
        this.setState({ ...this.state, loading: true, showFormRechazo: false, showActions: false });
        const solicitudRechazada = await rechazarSolicitud(form);
        if (solicitudRechazada) {
            assignMensaje('Solicitud Rechazada');
            this.setState({
                ...this.state,
                loading: false,
                confirmacion: false,
                showActions: true,
                botonNo: 'Cerrar'
            });
        } else {
            assignMensaje('Ha ocurrido un error, recargue la página y vuelva a intentarlo');
            this.setState({
                ...this.state,
                loading: false,
                confirmacion: false,
                showActions: true,
                botonNo: 'Cerrar'
            });
        }
    };

    handleRechazarCotizacion = async values => {
        const { queryParams, cotizacionActual, solicitudActual } = this.state;
        const {
            assignMensaje,
            rechazarCotizacion,
            usuarioAutenticado,
            editarItems
        } = this.props;
        this.setState({ ...this.state, loading: true, showFormRechazo: false, showActions: false });
        const itemsCotizacion = solicitudActual.cotizaciones.find(x => x.id === parseInt(queryParams.idCot)).item_cotizacion;
        const itemsCotizados = solicitudActual.items.filter(x => itemsCotizacion.some(y => y.item_recurso_id === x.id));

        await rechazarCotizacion(parseInt(queryParams.idCot), {
            ...cotizacionActual,
            estado_id: 24,
            comentario: values.comentario,
            cotizacion_id: parseInt(queryParams.idCot),
            fecha: moment().toISOString(),
            solicitud_recurso_id: parseInt(queryParams.idSol),
            usuario_id: usuarioAutenticado.data.id,
            aprobada: 0
        });

        let cotizacionesRestantes = solicitudActual.cotizaciones.filter(x => x.id !== parseInt(queryParams.idCot) && x.estado_id === 22);
        let itemsRestantesPendientes = [];
        for (let i = 0; i < cotizacionesRestantes.length; i++) {
            let itemsRestantes = solicitudActual.cotizaciones.find(x => x.id === cotizacionesRestantes[i].id).item_cotizacion;
            itemsRestantesPendientes = itemsRestantesPendientes.concat(itemsRestantes);
        }

        await Promise.all(
            itemsCotizados.map(async y => {
                if (y.estado === 22 && !itemsRestantesPendientes.some(x => x.item_recurso_id === y.id)) {
                    return {
                        items: await editarItems(y.id, {
                            ...y,
                            fecha_requerida: moment(y.fecha_requerida).toISOString(),
                            estado: 8
                        })
                    };
                }
            })
        );
        assignMensaje(`Cotización rechazada`);
        this.setState({ ...this.state, loading: false, confirmacion: false, showActions: true, botonNo: 'Cerrar' });
    };

    render() {
        const {
            loading,
            openAprobar,
            openModalRechazo,
            openModalRechazoSolicitud,
            confirmacion,
            botonSi,
            botonNo,
            showActions,
            showFormRechazo,
            esSolicitud
        } = this.state;
        const { mensaje } = this.props;

        return !openAprobar && !openModalRechazo && !openModalRechazoSolicitud ? (
            <Spinner />
        ) : (
            <div>
                <Modal
                    botonSi={botonSi ? botonSi : false}
                    botonNo={botonNo ? botonNo : false}
                    open={openAprobar}
                    disableBackClick={true}
                    disableEscapeKey={true}
                    handleClose={this.handleClose}
                    confirmacion={confirmacion}
                    accion={esSolicitud ? this.handleAprobarSolicitud : this.handleAprobarCotizacion}
                    showActions={showActions}
                    // showTitle={!loading}
                    title='Atención'
                    loading={loading}
                    fullWidth={true}>
                    <Box>
                        <Typography>
                            {mensaje.data}
                        </Typography>
                    </Box>
                </Modal>
                <Modal
                    open={openModalRechazo}
                    disableBackClick={true}
                    disableEscapeKey={true}
                    handleClose={this.handleClose}
                    confirmacion={confirmacion}
                    showActions={showActions}
                    title="Rechazar"
                    accion={false}
                    loading={loading}
                    fullWidth={true}>
                    <Box>
                        <Typography>{mensaje.data}</Typography>
                    </Box>
                    {showFormRechazo && <FormRechazoCotizacion onSubmit={this.handleRechazarCotizacion} />}
                </Modal>
                <Modal
                    open={openModalRechazoSolicitud}
                    handleClose={this.handleClose}
                    confirmacion={confirmacion}
                    title="Rechazar"
                    accion={false}
                    loading={loading}>
                    <Box>
                        <Typography>{mensaje.data}</Typography>
                    </Box>
                    {showFormRechazo && <FormRechazoSolicitud onSubmit={this.handleRechazarSolicitud} />}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        usuarioAutenticado: state.Usuario,
        mensaje: state.Mensajes,
        aprobador1: state.Aprobador1.data.esAprobador === true ? true : false,
        aprobador2: state.Aprobador2.data.esAprobador === true ? true : false,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesUsuarioDuck,
            ...solicitudesAprobarDuck,
            ...solicitudesDuck,
            ...cotizacionesDuck,
            ...mensajesDuck,
            ...itemsDuck,
            ...itemsCotizadosDuck,
            ...aprobador1Duck,
            ...aprobador2Duck,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AccionDirecta);
