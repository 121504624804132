import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { APROBADOR_GERENTE_GAF, USUARIO_COMPRAS_MENORES } from '../utils/constants';

export default function SimpleMenu(props) {
    const {
        handleCotizacionModal,
        id,
        estado,
        estado2,
        aprobador,
        aprobadorRango2,
        usuarioAutenticado,
        revisarCotizacion,
        dataCotizacion,
        aprobadorId,
        revisaCentroId,
        centroFirmaId,
        tieneItemsRecepcion
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                Acción
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {/* {
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleVerDocumento(idArchivo, "Cotización");
                        }}>
                        Ver Respaldo
                    </MenuItem>
                } */}
                {/* {((!revisaCentroId && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297"  */}
                {/* Se habilita como reemplazo de Nicolas a Sebastian en compras menores - 07/01/2025  */}
                {((!revisaCentroId && usuarioAutenticado.data.id == USUARIO_COMPRAS_MENORES)
                        // && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        // && dataCotizacion.monto_total_pesos < 300000) //Falta aprobación Compras Aline
                    || (!revisaCentroId && usuarioAutenticado.data.id == centroFirmaId 
                        && (dataCotizacion.es_gasto_reemb || dataCotizacion.es_tarifa_gestion 
                            || dataCotizacion.monto_total_pesos >= 300000)) //Falta aprobación Compras JRamos
                    || (revisaCentroId && dataCotizacion.es_gasto_reemb 
                        && (dataCotizacion.monto_total_pesos < 5000000 || aprobadorId)
                        // && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Gasto Reemb, falta aprobación GAF
                        && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Gasto Reemb, falta aprobación GAF
                    || (revisaCentroId && dataCotizacion.es_gasto_reemb && !aprobadorId && aprobadorRango2) //Gasto Reemb, falta aprobación Gerente
                    || (revisaCentroId && dataCotizacion.es_tarifa_gestion 
                        // && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Tarifa Gestión, falta aprobación GAF
                        && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Tarifa Gestión, falta aprobación GAF
                    || (revisaCentroId && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        && !aprobadorId && dataCotizacion.monto_total_pesos < 5000000 && aprobador) //Otros, falta aprobación Director Proyectos
                    || (revisaCentroId && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        && !aprobadorId && dataCotizacion.monto_total_pesos >= 5000000 && aprobadorRango2) //Otros, falta aprobación Gerente
                    || (revisaCentroId && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        // && aprobadorId && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Otros, falta aprobación GAF
                        && aprobadorId && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Otros, falta aprobación GAF
                    ) && estado2 === 'b' && (
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                handleCotizacionModal('aprobar', id)();
                            }}>
                            Aprobar
                        </MenuItem>
                    )}
                {/* {((!revisaCentroId && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297"  */}
                 {/* Se habilita como reemplazo de Nicolas a Sebastian en compras menores - 07/01/2025  */}
                {((!revisaCentroId && usuarioAutenticado.data.id == USUARIO_COMPRAS_MENORES)
                        // && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        // && dataCotizacion.monto_total_pesos < 300000) //Falta aprobación Compras Aline
                    || (!revisaCentroId && usuarioAutenticado.data.id == centroFirmaId 
                        && (dataCotizacion.es_gasto_reemb || dataCotizacion.es_tarifa_gestion 
                            || dataCotizacion.monto_total_pesos >= 300000)) //Falta aprobación Compras JRamos
                    || (revisaCentroId && dataCotizacion.es_gasto_reemb 
                        && (dataCotizacion.monto_total_pesos < 5000000 || aprobadorId)
                        // && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Gasto Reemb, falta aprobación GAF
                        && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Gasto Reemb, falta aprobación GAF
                    || (revisaCentroId && dataCotizacion.es_gasto_reemb && !aprobadorId && aprobadorRango2) //Gasto Reemb, falta aprobación Gerente
                    || (revisaCentroId && dataCotizacion.es_tarifa_gestion 
                        // && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Tarifa Gestión, falta aprobación GAF
                        && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Gasto Reemb, falta aprobación GAF
                    || (revisaCentroId && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        && !aprobadorId && dataCotizacion.monto_total_pesos < 5000000 && aprobador) //Otros, falta aprobación Director Proyectos
                    || (revisaCentroId && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        && !aprobadorId && dataCotizacion.monto_total_pesos >= 5000000 && aprobadorRango2) //Otros, falta aprobación Gerente
                    || (revisaCentroId && !dataCotizacion.es_gasto_reemb && !dataCotizacion.es_tarifa_gestion
                        // && aprobadorId && usuarioAutenticado.data.id == "5e0f291a70edaf00137fd297") //Otros, falta aprobación GAF
                        && aprobadorId && usuarioAutenticado.data.id == APROBADOR_GERENTE_GAF) //Otros, falta aprobación GAF
                    ) && estado2 === 'b' &&
                    !estado && (
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                handleCotizacionModal('rechazar', id)();
                            }}>
                            Rechazar
                        </MenuItem>
                    )}
                {estado2 === 'a' && revisarCotizacion && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleCotizacionModal('editar', id)();
                        }}>
                        Editar Cotización
                    </MenuItem>
                )}
                {estado2 === 'a' && revisarCotizacion && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleCotizacionModal('borrar', id)();
                        }}>
                        Borrar Cotización
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleCotizacionModal('ver', id)();
                    }}>
                    {revisarCotizacion && tieneItemsRecepcion ? "Recepción de Recursos" : "Ver Cotización"}
                </MenuItem>
            </Menu>
        </div>
    );
}
