const IDS_Usuarios = {
  Aline_Galimidi: {
    id_usuario: "5e0f291670edaf00137fcffd",    
  },
  Nicolas_Perez: {
    id_usuario: "611bebffadd7fa00257b0b59",    
  },
  Juan_Jose_Ramos: {
    id_usuario: "5e0f291a70edaf00137fd297",    
  },
  Luis_Recabarren: {
    id_usuario: "5e0f291a70edaf00137fd2f5",    
  },
  Marcelo_Baladron: {
    id_usuario: "657288001299d8001250f6b6",    
  },
  Eileen_Perez: {
    id_usuario: "64ac1bd5d1439e0021b2d3ee",    
  },
  Sebastian_Poblete: {
    id_usuario: "664829149d24f10012fd4d05",    
  },
};

export const USUARIO_COMPRAS_MENORES = IDS_Usuarios.Sebastian_Poblete.id_usuario; //"611bebffadd7fa00257b0b59"; Sebastian Poblete Reemplaza a Nicolás Perez - 2025.01.06
export const APROBADOR_GERENTE_GAF = IDS_Usuarios.Marcelo_Baladron.id_usuario; //"657288001299d8001250f6b6"; //ID de Marcelo Baladrón

export const PAIS_CHILE = "6645287f93aafbf0e42a0c11";
export const PAIS_PERU = "6645288793aafbf0e42a0c14";
export const GERENCIA_PERU = "655615b5b6eb2a0011438b4e";

//'5e0f291a70edaf00137fd2f5';   ID Luis Recabarren
//'657288001299d8001250f6b6';   ID Marcelo Baladrón
//"5e0f291a70edaf00137fd297"    ID JRAMOS
//"611bebffadd7fa00257b0b59"    ID Nicolás Peréz V.
//"5e0f291670edaf00137fcffd";   ID Aline Galimidi
